/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import './Dropdown.scss';
import Avatar from '../Avatar';
import UserContext from '../../context/userContext';
import { clearCookie } from '../../helpers/common';

function Dropdown() {
  const [showDropdown, setShowDropdown] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  const onLogout = () => {
    setUser(null);
    clearCookie('current_profile');
    clearCookie('itombs_token');
    clearCookie('itombs_sessionID');
    navigate('/user/login');
  };
  useEffect(
    () => () => {
      setShowDropdown(false);
    },
    [],
  );
  return (
    <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="icon">
        <Avatar sm url={user?.media?.profile_photo} />
        <span>{user?.name ?? ''}</span>
      </div>
      {showDropdown && (
        <div className="dropdown-content">
          <Link to="/user/feed">Home Feed</Link>
          <Link to="/user/choose-profile">Change Profile</Link>
          <Link to={`/user/profile?id=${user?._id}`}>View Profile</Link>
          <Link to="/user/edit-profile">Edit Profile</Link>
          <span
            onClick={() => {
              onLogout();
            }}>
            Logout
          </span>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
