import React, { useState } from 'react';

function Footer() {
  const [user] = useState(true);

  return (
    <div className="footer">
      {!user && (
        <div className="container">
          <a href="/" className="btn btn-white">
            Sign In
          </a>
        </div>
      )}
      <div className="bottom-footer">
        <div className="container">
          <span className="copyright">
            <a href="/">Copyright iTombs {new Date().getFullYear()}</a>, All rights reserved
          </span>
          <p>
            <a href="/">Terms</a> | <a href="/">Privacy Policy</a> | <a href="/">Contact Us</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
