/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faPlusCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from '@gatsbyjs/reach-router';
import Dropdown from '../Dropdown';
import Button from '../Button';
import UserContext from '../../context/userContext';

function Header() {
  const location = useLocation();
  const [showIcons] = useState(location?.pathname !== '/');
  const { user, setShowFollowers, showFollowers } = useContext(UserContext);

  const handleShowFollowers = () => {
    setShowFollowers(prev => !prev);
    if (showFollowers) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };
  return (
    <div className="header">
      <div className="container">
        <div className="logo">
          <a href="/">
            <StaticImage src="../../images/logo.png" alt="img description" />
          </a>
        </div>
        {showIcons && user ? (
          <div className="icon-holder">
            <div
              id="follower-list-icon"
              className="follower-icon"
              onClick={() => {
                handleShowFollowers();
              }}>
              <div className="icon">
                <FontAwesomeIcon icon={faUsers} /> <span>Followers</span>
              </div>
            </div>
            <Link to={`/user/profile?id=${user?._id}&saved=${true}`}>
              <div className="icon">
                <FontAwesomeIcon icon={faHeart} /> <span>Favorite Posts</span>
              </div>
            </Link>
            <Link href="/user/create-post">
              <div className="icon">
                <FontAwesomeIcon icon={faPlusCircle} /> <span>Create Post</span>
              </div>
            </Link>

            <Dropdown />

            <Link href="https://ilegacybook.com/collections/all" target="_blank">
              <div className="icon">
                <FontAwesomeIcon icon={faCartShopping} /> <span>Add to cart</span>
              </div>
            </Link>
          </div>
        ) : (
          <div className="btn-login">
            <Button
              variant="primary"
              onClick={() => {
                navigate('/user/login');
              }}>
              <StaticImage className="ico" src="../../images/ico-user.svg" alt="img description" />
              Login
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
