/* eslint-disable no-restricted-syntax */
import jwt_decode from 'jwt-decode';

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
  document.cookie = `${name}=${value || ''}; path=/; domain=.cre-data.com`;

  return true;
};

export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.cre-data.com`;

  return true;
};

export const convertToBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

export const shortenString = (str, len = 10) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

export const decodeToken = token => {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    console.log('Error decoding token:', error);
    return null;
  }
};

export const formatTimeAgo = timestampInMilliseconds => {
  const secondsAgo = Math.floor((Date.now() - timestampInMilliseconds) / 1000);

  const intervals = [
    { label: 'w', value: 604800 }, // 1 week = 60 seconds * 60 minutes * 24 hours * 7 days
    { label: 'd', value: 86400 }, // 1 day = 60 seconds * 60 minutes * 24 hours
    { label: 'h', value: 3600 }, // 1 hour = 60 seconds * 60 minutes
    { label: 'm', value: 60 }, // 1 minute = 60 seconds
  ];

  for (const interval of intervals) {
    const count = Math.floor(secondsAgo / interval.value);
    if (count >= 1) {
      return `${count}${interval.label} ago`;
    }
  }

  return 'just now';
};
export const urls = {
  // dev: 'https://api.opzoom.com/itombs/dev',
  dev: 'https://webdev-itombs.opzoom.com',
  prod: 'https://api.opzoom.com/itombs/prod',
  local: 'http://localhost:8000',
};

export const isUser = () => {
  const profileId = getCookie('current_profile');
  // todo check session
  if (profileId) {
    return true;
  }
  return false;
};
