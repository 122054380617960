import React, { createContext, useMemo, useState, useEffect } from 'react';
import userService from '../services/userService';
import { getCookie } from '../helpers/common';
import Toast from '../components/Toast';

// Create the user context
const UserContext = createContext();

// Create a provider component to wrap your app
export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showFollowers, setShowFollowers] = useState(false);
  const [followedProfiles, setFollowedProfiles] = useState([]);
  useEffect(() => {
    if (isLoggedIn) {
      const fn = async () => {
        const profileId = getCookie('current_profile');
        const res = await userService.getCurrentProfile(profileId);
        setUser(res?.data);
      };
      fn();
    }
  }, [isLoggedIn]);

  const hasPermission = (callback = () => {}) => {
    if (user) {
      callback();
    } else {
      Toast({
        type: 'error',
        message: 'Please log in to perform this action.',
      });
    }
  };
  // Memoize the user context value using useMemo
  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoggedIn,
      setIsLoggedIn,
      followedProfiles,
      setFollowedProfiles,
      setShowFollowers,
      showFollowers,
      hasPermission,
    }),
    [user, followedProfiles, showFollowers],
  );

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
}

export default UserContext;
