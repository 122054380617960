import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './Avatar.scss';
import profile from '../../images/profile_no.png';

function Avatar({ sm, md, url, ...rest }) {
  return (
    <div className={`holder ${sm ? 'sm' : ''} ${md ? 'md' : ''}`} {...rest}>
      <div className="avatar" style={{ backgroundImage: `url(${url ?? profile})` }} />
      {!sm && <span> User Name</span>}
    </div>
  );
}

export default Avatar;
