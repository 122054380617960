import React from 'react';

import { UserProvider } from './src/context/userContext';

const Layout = require('./src/components/layout').default;

export const wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  // eslint-disable-next-line react/jsx-filename-extension
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement = ({ element }) => <UserProvider>{element}</UserProvider>;
