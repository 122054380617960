import React from 'react';
import './Button.scss';

function Button({ type = 'button', sm, loading, className, variant, children, ...rest }) {
  return (
    <button
      type={type}
      {...rest}
      className={`${variant === 'primary' ? 'my-btn' : ''}
      ${sm ? 'sm' : ''}
      ${className} ${loading ? 'loading' : ''}`}>
      {loading ? 'loading...' : children}
    </button>
  );
}

export default Button;
