/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Header from './Header';
import Footer from './Footer';
import '../styles/global.css';
import '../styles/variables.scss';
import '../styles/common.scss';

function Layout({ children, location, hideLayout }) {
  const hideTemplates = [
    '/user/login',
    '/user/login/',
    '/user/verify-otp',
    '/select-profile',
    '/select-profile/',
    '/user/choose-profile',
    '/user/choose-profile/',
    '/user/link-profile-qr',
    '/user/link-profile-qr/',
  ];
  const hideTemplate = !!hideTemplates.includes(location?.pathname);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <ToastContainer />
      <div className="wrapper">
        {hideTemplate ? (
          <main>{children}</main>
        ) : (
          <>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} hideTemplate={hideTemplate} />
            <main>{children}</main>
            <Footer />
          </>
        )}
      </div>
    </>
  );
}

export default Layout;
