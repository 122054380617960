import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import './Toast.scss';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleXmark, faCross } from '@fortawesome/free-solid-svg-icons';

function Toast({ type, message, ...props }) {
  return toast(
    <div className={`styled-alert ${type ?? ''}`} {...props}>
      <span className="alert-icon">
        {type === 'success' ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircleXmark} />}
      </span>
      <p className="message">{message ?? 'Something went wrong'}</p>
    </div>,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
    },
  );
}

export default Toast;
